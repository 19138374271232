exports.install = function(Vue) {
	
	Vue.prototype.deepClone = function(obj) {
		var _obj = JSON.stringify(obj);
		return JSON.parse(_obj);
	}

	Vue.prototype.go = function(url, query, _blank = false) {
		

		if (_blank) {
			let routeUrl = this.router.resolve({
				path: url,
				query: query
			});
			window.open(routeUrl.href, '_blank');
		} else {
			this.router.push({
				path: url,
				query: query
			})
		}

	}

	Vue.prototype.base64Encode = function(content) {
		let Base64 = require('js-base64').Base64
		return Base64.encode(content);
	}

	Vue.prototype.getParam = function(key) {

		let value = null;

		if (key in this.$route.query) {
			value = this.$route.query[key];
		} else if (key in this.$route.params) {
			value = this.$route.params[key];
		}

		return value;
	}

}
