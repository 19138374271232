"use strict";

import Vue from 'vue';
import axios from "axios";
import qs from 'qs'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        let token = window.sessionStorage.getItem('token')
        if (token) {
            config.headers.Authorization = token
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {

        let res = response.data;
        if (res.code == 200) {
            if (res.message) Vue.prototype.$message.success(res.message)
            return response;
        } else if (res.code == 401) {
            Vue.prototype.$message.warning(res.message);
            Vue.prototype.go('/login');
        } else if (res.code >= 510) {
            Vue.prototype.$message.warning(res.message)
        } else if (res.code < 510) {
            Vue.prototype.$message.error(`请求后端未知错误 { ${res.error} }`)
            console.error(res.stackMessage)
        }

		return response;

    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};


Vue.use(Plugin)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        _axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        _axios.post(url, qs.stringify(data))
            .then(response => {
                resolve(response.data);
            }).catch(err => {
            reject(err)
        })
    })
}

export function post2(url, data = {}) {
    return new Promise((resolve, reject) => {
        _axios.post(url, data)
            .then(response => {
                resolve(response.data);
            }).catch(err => {
            reject(err)
        })
    })
}

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        _axios.put(url, data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    });
}

export function put2(url, params) {
    return _axios({
        method: 'put',
        url: url,
        data: qs.stringify(params)
    })
}

export function $delete(url, params) {
    return new Promise((resolve, reject) => {
        return _axios({
            method: 'delete',
            url,
            params,
        }).then(res => {
            resolve(res.data)
        })
            .catch(err => {
                reject(err)
            })
    });
}

export function download(fileName, url, params = {}, beforeCallback, afterCallback) {

	return new Promise((resolve, reject) => {

		if (beforeCallback) beforeCallback()

		_axios({
			headers: {'Content-Type': 'application/json'},
			responseType: 'blob',
			method: 'get',
			url: url,
			params,
			timeout: 60000
		}).then(response => {
			let link = document.createElement('a')
			let blob = new Blob([response.data])
			link.style.display = 'none'
			link.href = URL.createObjectURL(blob)
			link.download = fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			if (afterCallback) afterCallback()

		}).catch(err => {
			console.log(err)
			reject(err)
		})
	});
}

export default Plugin;
