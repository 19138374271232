import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue'),
		children: [
			{
				name: 'index',
				path: '/index',
				component: () => import('../views/Index.vue'),
			},
			//有效项目信息
			{
				name: 'project-effective-list',
				path: '/project-effective-list',
				component: () => import('../views/ProjectEffectiveList.vue'),
			},
			{
				name: 'project-effective-list',
				path: '/project-effective-list/:page',
				component: () => import('../views/ProjectEffectiveList.vue'),
			}, {
				name: 'project-effective-new',
				path: '/project-effective-editor',
				component: () => import('../views/ProjectEffectiveEditor.vue'),
			}, {
				name: 'project-effective-editor',
				path: '/project-effective-editor/:id',
				component: () => import('../views/ProjectEffectiveEditor.vue'),
			},

			//跟踪洽谈
			{
				name: 'project-tracking-list',
				path: '/project-tracking-list',
				component: () => import('../views/ProjectTrackingList.vue'),
			}, {
				name: 'project-tracking-list',
				path: '/project-tracking-list/:page',
				component: () => import('../views/ProjectTrackingList.vue'),
			},{
				name: 'project-tracking-new',
				path: '/project-tracking-editor/:page',
				component: () => import('../views/ProjectTrackingEditor.vue'),
			}, {
				name: 'project-tracking-editor',
				path: '/project-tracking-editor/:page/:id',
				component: () => import('../views/ProjectTrackingEditor.vue'),
			},
			{
				name: 'project-tracking-approval',
				path: '/project-tracking-editor/:page/:id/:approval',
				component: () => import('../views/ProjectTrackingEditor.vue'),
			},

			//入园预审
			{
				name: 'project-pre-trial-list',
				path: '/project-pre-trial-list',
				component: () => import('../views/ProjectPreTrialList.vue'),
			},{
				name: 'project-pre-trial-list',
				path: '/project-pre-trial-list/:page',
				component: () => import('../views/ProjectPreTrialList.vue'),
			}, {
				name: 'project-pre-trial-new',
				path: '/project-pre-trial-editor/:page',
				component: () => import('../views/ProjectPreTrialEditor.vue'),
			}, {
				name: 'project-pre-trial-editor',
				path: '/project-pre-trial-editor/:page/:id',
				component: () => import('../views/ProjectPreTrialEditor.vue'),
			},
			{
				name: 'project-pre-trial-approval',
				path: '/project-pre-trial-editor/:page/:id/:approval',
				component: () => import('../views/ProjectPreTrialEditor.vue'),
			},

			//签约项目
			{
				name: 'project-signing-list',
				path: '/project-signing-list',
				component: () => import('../views/ProjectSigningList.vue'),
			},{
				name: 'project-signing-list',
				path: '/project-signing-list/:page',
				component: () => import('../views/ProjectSigningList.vue'),
			}, {
				name: 'project-signing-new',
				path: '/project-signing-editor/:page',
				component: () => import('../views/ProjectSigningEditor.vue'),
			}, {
				name: 'project-signing-editor',
				path: '/project-signing-editor/:page/:id',
				component: () => import('../views/ProjectSigningEditor.vue'),
			},
			{
				name: 'project-signing-approval',
				path: '/project-signing-editor/:page/:id/:approval',
				component: () => import('../views/ProjectSigningEditor.vue'),
			},

			//注册项目
			{
				name: 'project-register-list',
				path: '/project-register-list',
				component: () => import('../views/ProjectRegisterList.vue'),
			},{
				name: 'project-register-list',
				path: '/project-register-list/:page',
				component: () => import('../views/ProjectRegisterList.vue'),
			}, {
				name: 'project-register-new',
				path: '/project-register-editor/:page',
				component: () => import('../views/ProjectRegisterEditor.vue'),
			}, {
				name: 'project-register-editor',
				path: '/project-register-editor/:page/:id',
				component: () => import('../views/ProjectRegisterEditor.vue'),
			},
			{
				name: 'project-register-approval',
				path: '/project-register-editor/:page/:id/:approval',
				component: () => import('../views/ProjectRegisterEditor.vue'),
			},

			//驻外招商
			{
				name: 'project-overseas-list',
				path: '/project-overseas-list',
				component: () => import('../views/ProjectOverseasList.vue'),
			},{
				name: 'project-overseas-list',
				path: '/project-overseas-list/:page',
				component: () => import('../views/ProjectOverseasList.vue'),
			}, {
				name: 'project-overseas-new',
				path: '/project-overseas-editor/:page',
				component: () => import('../views/ProjectOverseasEditor.vue'),
			}, {
				name: 'project-overseas-editor',
				path: '/project-overseas-editor/:page/:id',
				component: () => import('../views/ProjectOverseasEditor.vue'),
			},
			{
				name: 'project-overseas-approval',
				path: '/project-overseas-editor/:page/:id/:approval',
				component: () => import('../views/ProjectOverseasEditor.vue'),
			},

			//接待客商
			{
				name: 'project-merchants-list',
				path: '/project-merchants-list',
				component: () => import('../views/ProjectMerchantsList.vue'),
			},{
				name: 'project-merchants-list',
				path: '/project-merchants-list/:page',
				component: () => import('../views/ProjectMerchantsList.vue'),
			}, {
				name: 'project-merchants-new',
				path: '/project-merchants-editor/:page',
				component: () => import('../views/ProjectMerchantsEditor.vue'),
			}, {
				name: 'project-merchants-editor',
				path: '/project-merchants-editor/:page/:id',
				component: () => import('../views/ProjectMerchantsEditor.vue'),
			},
			{
				name: 'project-merchants-approval',
				path: '/project-merchants-editor/:page/:id/:approval',
				component: () => import('../views/ProjectMerchantsEditor.vue'),
			},
			
			//填报信息汇总
			{
				path: '/collect',
				name: 'Collect',
				component: () => import('../views/Collect.vue'),
			},
			//部门管理
			{
				path: '/manager-dept',
				name: '/manager-dept',
				component: () => import('../views/ManagerDept.vue'),
			},
			//用户管理
			{
				path: '/manager-user',
				name: '/manager-user',
				component: () => import('../views/ManagerUser.vue'),
			},
			//权限管理
			{
				path: '/manager-role',
				name: '/manager-role',
				component: () => import('../views/ManagerRole.vue'),
			},

		]
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue'),
	},
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	let token = window.sessionStorage.getItem('token')

	if (token) {
		next();
	} else {
		if (to.path === '/login') return next();
		next({
			path: '/login'
		})
	}
})


export default router
